export const environment = {
  production: true,
  hmr: false,
  appInsightsKey: '49710adb-d9f6-423f-95fa-f7ad5aab749c',
  firebase: {
    apiKey: 'AIzaSyDvR9E271EY0jYXzTnNqqyq0NN0Mow2jL8',
    authDomain: 'mijn-rova.firebaseapp.com',
    projectId: 'mijn-rova',
    storageBucket: 'mijn-rova.appspot.com',
    messagingSenderId: '327748098991',
    appId: '1:327748098991:web:468f8337b3f42678ee6d5a',
    measurementId: 'G-3KXT09KNMQ',
    vapidKey: 'BGbyo1-M3cS_mJQj99xPHnY9-w7rEeblutOW9DEWRMA-B2Kc-8NleCKqltAckbwr3HXY8Hx5sENYn55REifxDvI',
  },
};
